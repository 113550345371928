import classNames from 'classnames';
import React from 'react';
import { LiraAdvContainer } from '@sbt-web/adv';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';

export const AdsAdDetailsBlock = function ({
  className,
}: {
  className?: string;
}) {
  return (
    <div
      className={classNames('adsense-bottom-container', className)}
      style={{ margin: '20px', width: '620px' }}
    >
      <LiraAdvContainer
        idForAd={LiraContainerIds.adView.bottom}
        style={{ display: 'block' }}
      />
    </div>
  );
};
