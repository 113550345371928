import { LiraAdvContainer } from '@sbt-web/adv';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';
import React, { useEffect } from 'react';

export const resetLoaded = () => {
  loaded = false;
};

let loaded = false;
export const LiraLazySlotReplyBox = () => {
  useEffect(() => {
    if (loaded) return;
    loaded = true;
    window.liraSDKQueue?.push(() => {
      window.liraSdk
        .fetchLazyLoadAd([LiraContainerIds.adView.replyBox])
        .then(() => {
          loaded = false;
        });
    });
  }, []);
  return <LiraAdvContainer idForAd={LiraContainerIds.adView.replyBox} />;
};
