import React from 'react';
import { LiraAdvContainer } from '@sbt-web/adv';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';

interface Props {
  isMobile: boolean;
}

export const AdsAdDetailsMSiteBlock = function ({ isMobile }: Props) {
  return isMobile ? (
    <LiraAdvContainer
      idForAd={LiraContainerIds.adView.bottom}
      style={{ display: 'block' }}
    />
  ) : null;
};
