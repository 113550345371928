import type {
  ShopDayHours,
  ShopOpeningTimeDay,
  ShopOpeningTime,
  ShopOpeningTimePeriod,
} from '@sbt-web/network/types';
import { IOpeningTimeRow } from './OpeningTimeTable';

enum EnglishDayName {
  MON = 'lun',
  TUE = 'mar',
  WED = 'mer',
  THU = 'gio',
  FRI = 'ven',
  SAT = 'sab',
  SUN = 'dom',
}

enum DayType {
  DAY = 'day',
  TIME = 'time',
}

const getEnglishName = (key: string): string =>
  EnglishDayName[<keyof typeof EnglishDayName>key.toUpperCase()];

const getColumnData = (dayData: ShopDayHours, type?: DayType): string => {
  if (type === DayType.DAY) {
    return dayData.status === 'open' ? '(continuato)' : '';
  }

  if (dayData.status === 'closed') {
    return 'Chiuso';
  }
  if (dayData.status === 'open') {
    return `${dayData.from} - ${dayData.to}`;
  }

  return '-';
};

export const getOpeningTime = (
  openingTime: ShopOpeningTime
): IOpeningTimeRow[] =>
  Object.entries(openingTime).reduce(
    (acc: IOpeningTimeRow[], [key, dayData]) => {
      const type = Object.keys(dayData).includes(DayType.DAY)
        ? DayType.DAY
        : DayType.TIME;

      return [
        ...acc,
        {
          type,
          name: getEnglishName(key),
          firstColumn:
            type === DayType.DAY
              ? getColumnData((dayData as ShopOpeningTimeDay).day)
              : getColumnData((dayData as ShopOpeningTimePeriod).am),
          secondColumn:
            type === DayType.DAY
              ? getColumnData((dayData as ShopOpeningTimeDay).day, type)
              : `| ${getColumnData((dayData as ShopOpeningTimePeriod).pm)}`,
        },
      ];
    },
    []
  );
