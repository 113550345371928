import { useLiraLazyOverlayGalleryForPro } from './useLiraLazyOverlayGalleryForPro';
import { useLiraLazyOverlayGalleryForPrivate } from './useLiraLazyOverlayGalleryForPrivate';
import { AdItem } from '@sbt-web/networking';

export const useLiraLazyOverlayGallery = ({
  ad,
  isMobile,
}: {
  ad: AdItem;
  isMobile: boolean;
}): void => {
  useLiraLazyOverlayGalleryForPro({ ad, isMobile });
  useLiraLazyOverlayGalleryForPrivate({ ad, isDesktop: !isMobile });
};
