import React, { useContext, useEffect, useState } from 'react';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { useViewport } from '@sbt-web/hooks';
import { AdsGAMPlacement } from '@client/components/Adv/GPT';

// This toggle handles the placement below the gallery (for desktop)
const DESKTOP_BELOW_GALLERY_PLACEMENT_TOGGLE = 'subito.web.adv.below-gallery';
let alreadyFetched = false;

export const LiraLazySlotBelowGalleryForDesktop = ({
  cssClass,
}: {
  cssClass: string;
}) => {
  const { optimizely } = useContext(OptimizelySubitoContext);
  const { isDesktop } = useViewport();
  const [showBannerBelowGallery, setShowBannerBelowGallery] = useState(false);

  useEffect(() => {
    if (isDesktop) {
      // Please not under houston toggle, handle in Lira UI directly!!
      optimizely?.onReady().then(() => {
        setShowBannerBelowGallery(
          optimizely.isFeatureEnabled(DESKTOP_BELOW_GALLERY_PLACEMENT_TOGGLE)
        );
      });
    }
  }, [isDesktop, optimizely]);

  useEffect(() => {
    if (!alreadyFetched && showBannerBelowGallery) {
      alreadyFetched = true;
      window.liraSDKQueue?.push(() => {
        window.liraSdk.fetchLazyLoadAd([LiraContainerIds.adView.belowGallery]);
      });
    }
  }, [showBannerBelowGallery]);

  return showBannerBelowGallery ? (
    <AdsGAMPlacement
      classes={[cssClass]}
      id={LiraContainerIds.adView.belowGallery}
    />
  ) : null;
};
