import { HebeModel, Vertical } from '@sbt-web/adv';
import { ADSENSE_STYLES } from '@client/components/Adv/AdSense';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';

/**
 * The bidders for the skyscraper ad slots.
 */
const skyscraperBidders: Array<{
  name: string;
  params: Record<string, string | number>;
}> = [
  {
    name: 'criteo',
    params: {
      networkId: 280,
    },
  },
  {
    name: 'appnexus',
    params: {
      placementId: 19698617,
    },
  },
  {
    name: 'appnexus',
    params: {
      placementId: 19698611,
    },
  },
  {
    name: 'teads',
    params: {
      pageId: 149522,
      placementId: 163688,
    },
  },
  {
    name: 'pubmatic',
    params: {
      publisherId: '81997',
      adSlot: 'Subito_300x600-hb@300x600',
    },
  },
  {
    name: 'pubmatic',
    params: {
      publisherId: '81997',
      adSlot: 'Subito_120x600-hb@120x600',
    },
  },
  {
    name: 'pubmatic',
    params: {
      publisherId: '81997',
      adSlot: 'Subito_160x600-hb@160x600',
    },
  },
  {
    name: 'ix',
    params: {
      siteId: 1131818,
    },
  },
  {
    name: 'rubicon',
    params: {
      accountId: 23660,
      siteId: 381482,
      zoneId: 2114030,
    },
  },
  {
    name: 'openx',
    params: {
      delDomain: 'adevintaitaly-d.openx.net',
      unit: 541156882,
    },
  },
  {
    name: 'openx',
    params: {
      delDomain: 'adevintaitaly-d.openx.net',
      unit: 541156881,
    },
  },
];

const DesktopVerticalAdsenseStyle: Record<Vertical, string> = {
  subito: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_TUTTI,
  jobs: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_JOBS,
  motors: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_MOTORS,
  market: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_MARKET,
  realestate: ADSENSE_STYLES.DESKTOP_SHOPPING_LIGHT_REALESTATE,
};

const DesktopVerticalAdsenseBelowTheFoldChannel: Record<Vertical, string> = {
  subito: 'DESKTOP-BOTTOM-ADP-TUTTI-0-LIGHT-NO',
  jobs: 'DESKTOP-BOTTOM-ADP-JOBS-0-LIGHT-NO',
  motors: 'DESKTOP-BOTTOM-ADP-MOTORS-0-LIGHT-NO',
  market: 'DESKTOP-BOTTOM-ADP-MARKET-0-LIGHT-NO',
  realestate: 'DESKTOP-BOTTOM-ADP-REALESTATE-0-LIGHT-NO',
};

const MsiteVerticalAdsenseBelowTheFoldChannel: Record<Vertical, string> = {
  subito: 'MSITE-BOTTOM-ADP-TUTTI-0-LIGHT-NO',
  jobs: 'MSITE-BOTTOM-ADP-JOBS-0-LIGHT-NO',
  motors: 'MSITE-BOTTOM-ADP-MOTORS-0-LIGHT-NO',
  market: 'MSITE-BOTTOM-ADP-MARKET-0-LIGHT-NO',
  realestate: 'MSITE-BOTTOM-ADP-REALESTATE-0-LIGHT-NO',
};

const MsiteVerticalAdsenseStyle: Record<Vertical, string> = {
  subito: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_TUTTI,
  jobs: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_JOBS,
  motors: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_MOTORS,
  market: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_MARKET,
  realestate: ADSENSE_STYLES.MOBILE_SHOPPING_LIGHT_REALESTATE,
};

export const getDesktopFallbackConfig = (vertical: Vertical): HebeModel => {
  return {
    name: 'adview',
    page_level_settings: {
      adsense: {
        pubId: 'partner-subito',
        style_id: DesktopVerticalAdsenseStyle[vertical],
        channel_id: 'DESKTOP-ADVIEW',
        adsafe: 'high',
        hl: 'it-IT',
        linkTarget: '_blank',
        adPage: 1,
      },
    },
    slots: [
      {
        //Ex: apn_sponsor_adview
        container_id: LiraContainerIds.adView.sponsorGallery,
        name: 'Overlay sponsor over gallery',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-adview-sponsor`,
              sizes: ['460x90'],
            },
          ],
        },
      },
      {
        //Ex: apn_gallery_adview
        container_id: LiraContainerIds.adView.imageIntoGalleryForPro,
        name: 'Adv in the gallery specific for Pro',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-adview-gallery`,
              sizes: ['620x393'],
            },
          ],
        },
      },
      {
        //Ex: apn_aside
        container_id: LiraContainerIds.adView.aside,
        name: 'Side ad in the adview',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-adview-box1`,
              sizes: ['300x250', '160x600', '300x600'],
              bidding_systems: [
                {
                  name: 'criteo',
                  params: {
                    networkId: 280,
                  },
                },
                {
                  name: 'ix',
                  params: {
                    siteId: 1131818,
                  },
                },
                {
                  name: 'teads',
                  params: {
                    pageId: 149522,
                    placementId: 163688,
                  },
                },
                {
                  name: 'rubicon',
                  params: {
                    accountId: 23660,
                    siteId: 381482,
                    zoneId: 2114030,
                  },
                },
                {
                  name: 'openx',
                  params: {
                    delDomain: 'adevintaitaly-d.openx.net',
                    unit: 541156882,
                  },
                },
                {
                  name: 'openx',
                  params: {
                    delDomain: 'adevintaitaly-d.openx.net',
                    unit: 541156881,
                  },
                },
                {
                  name: 'appnexus',
                  params: {
                    placementId: 19698611,
                  },
                },
                {
                  name: 'appnexus',
                  params: {
                    placementId: 19698617,
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_160x600-hb@160x600',
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_300x600-hb@300x600',
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_300x250-hb@300x250',
                  },
                },
              ],
            },
          ],
        },
      },
      {
        //Ex: apn_below_gallery
        container_id: LiraContainerIds.adView.belowGallery,
        name: 'Below the gallery',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-adview-contentbelowimage`,
              sizes: [
                '600x160',
                '600x120',
                '320x100',
                '320x50',
                '300x100',
                '300x50',
              ],
            },
          ],
        },
      },
      {
        //Ex: apn_box_adreply
        container_id: LiraContainerIds.adView.replyBox,
        name: 'when user sends a reply',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-login-rispostaannuncio`,
              sizes: ['300x250'],
              bidding_systems: [
                {
                  name: 'criteo',
                  params: {
                    networkId: 280,
                  },
                },
                {
                  name: 'ix',
                  params: {
                    siteId: 1131818,
                  },
                },
                {
                  name: 'teads',
                  params: {
                    pageId: 149522,
                    placementId: 163688,
                  },
                },
                {
                  name: 'rubicon',
                  params: {
                    accountId: 23660,
                    siteId: 381482,
                    zoneId: 2114030,
                  },
                },
                {
                  name: 'openx',
                  params: {
                    delDomain: 'adevintaitaly-d.openx.net',
                    unit: 541156881,
                  },
                },
                {
                  name: 'appnexus',
                  params: {
                    placementId: 19698617,
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_300x250-hb@300x250',
                  },
                },
              ],
            },
          ],
        },
      },
      {
        //Ex: video_preroll
        container_id: LiraContainerIds.adView.videoPreroll,
        name: 'Video preroll',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-adview-video${process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT === 'prod' ? '' : '-test'}`,
              sizes: ['1x1', '624x468', '640x480'],
            },
          ],
        },
      },
      {
        //Ex: apn_top_tag
        container_id: LiraContainerIds.adView.top,
        name: 'The top ad',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-adview-top`,
              sizes: ['728x90', '970x250'],
              bidding_systems: [
                {
                  name: 'criteo',
                  params: {
                    networkId: 280,
                  },
                },
                {
                  name: 'appnexus',
                  params: {
                    placementId: 19698601,
                  },
                },
                {
                  name: 'teads',
                  params: {
                    pageId: 149522,
                    placementId: 163688,
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_728x90-hb@728x90',
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_970x250-hb@970x250',
                  },
                },
                {
                  name: 'ix',
                  params: {
                    siteId: 1131818,
                  },
                },
                {
                  name: 'rubicon',
                  params: {
                    accountId: 23660,
                    siteId: 381482,
                    zoneId: 2114030,
                  },
                },
                {
                  name: 'openx',
                  params: {
                    delDomain: 'adevintaitaly-d.openx.net',
                    unit: 541156883,
                  },
                },
              ],
              refresh: {
                enabled: true,
                interval: 30,
              },
            },
          ],
        },
      },
      {
        //Ex: desktop-bottom-addetail
        container_id: LiraContainerIds.adView.bottom,
        name: 'Adsense bottom',
        configurations: {
          adsense: [
            {
              sequence: 0,
              number_of_ads: 5,
              channel_id: DesktopVerticalAdsenseBelowTheFoldChannel[vertical],
            },
          ],
          gam: [
            {
              sequence: 1,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-adview-fallbackbottom`,
              sizes: ['300x250'],
              bidding_systems: [
                {
                  name: 'criteo',
                  params: {
                    networkId: 280,
                  },
                },
                {
                  name: 'appnexus',
                  params: {
                    placementId: 19698617,
                  },
                },
                {
                  name: 'teads',
                  params: {
                    pageId: 149522,
                    placementId: 163688,
                  },
                },
                {
                  name: 'ix',
                  params: {
                    siteId: 1131818,
                  },
                },
                {
                  name: 'rubicon',
                  params: {
                    accountId: 23660,
                    siteId: 381482,
                    zoneId: 2114030,
                  },
                },
                {
                  name: 'openx',
                  params: {
                    delDomain: 'adevintaitaly-d.openx.net',
                    unit: 541156881,
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_300x250-hb@300x250',
                  },
                },
              ],
            },
          ],
        },
      },
      {
        //Ex: apn_skyscraper_tag_left
        container_id: LiraContainerIds.adView.skyscraperLeft,
        name: 'The skyscraper on the left',
        show_when_unfilled: LiraContainerIds.adView.skin,
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-adview-skyscraperleft`,
              sizes: ['300x600', '160x600', '120x600'],
              bidding_systems: skyscraperBidders,
              refresh: {
                enabled: true,
                interval: 30,
              },
            },
          ],
        },
      },
      {
        //Ex: apn_skyscraper_tag_right
        container_id: LiraContainerIds.adView.skyscraperRight,
        name: 'The skyscraper on the right',
        show_when_unfilled: LiraContainerIds.adView.skin,
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-adview-skyscraperright`,
              sizes: ['300x600', '160x600', '120x600'],
              bidding_systems: skyscraperBidders,
              refresh: {
                enabled: true,
                interval: 30,
              },
            },
          ],
        },
      },
      {
        //Ex: apn_skin_tag
        container_id: LiraContainerIds.adView.skin,
        name: 'The skin',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-adview-skin`,
              sizes: ['1x1', '2x2', '1920x880', '1800x1000', '1920x1080'],
              bidding_systems: [
                {
                  name: 'appnexus',
                  params: {
                    placementId: 19832817,
                  },
                },
              ],
            },
          ],
        },
      },
      {
        //Ex: apn_gallery_adv_adview
        container_id: LiraContainerIds.adView.imageIntoGalleryForPrivate,
        name: 'Overlay sponsor over gallery for Private',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-desktop-adview-galleryadv`,
              sizes: ['320x250', '620x393'],
            },
          ],
        },
      },
    ],
  };
};

export const getMsiteFallbackConfig = (vertical: Vertical): HebeModel => {
  return {
    name: 'adview',
    page_level_settings: {
      adsense: {
        pubId: 'partner-subito',
        style_id: MsiteVerticalAdsenseStyle[vertical],
        channel_id: 'MSITE-ADVIEW',
        adsafe: 'high',
        hl: 'it-IT',
        linkTarget: '_blank',
        adPage: 1,
      },
    },
    slots: [
      {
        //Ex: gpt-msite-adview-1
        container_id: LiraContainerIds.adView.boxBelow1,
        name: 'Below the Ad description',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-msite-adview-box1`,
              sizes: ['300x250'],
              bidding_systems: [
                {
                  name: 'criteo',
                  params: {
                    networkId: 280,
                  },
                },
                {
                  name: 'teads',
                  params: {
                    pageId: 149522,
                    placementId: 163688,
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_320x50-hb@320x50',
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_320x100-hb@320x100',
                  },
                },

                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_300x250-hb@300x250',
                  },
                },
                {
                  name: 'rubicon',
                  params: {
                    accountId: 23660,
                    siteId: 381482,
                    zoneId: 2114030,
                  },
                },
                {
                  name: 'openx',
                  params: {
                    delDomain: 'adevintaitaly-d.openx.net',
                    unit: 541156884,
                  },
                },
                {
                  name: 'ix',
                  params: {
                    siteId: 1131820,
                  },
                },
                {
                  name: 'appnexus',
                  params: {
                    placementId: 19698628,
                  },
                },
              ],
              refresh: {
                enabled: true,
                interval: 30,
              },
            },
          ],
        },
      },
      {
        //Ex: apn_adview_bottom
        container_id: LiraContainerIds.adView.boxBelow2,
        name: 'Below the Ad description 2',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-msite-adview-box2`,
              sizes: ['300x250', '320x50'],
              bidding_systems: [
                {
                  name: 'criteo',
                  params: {
                    networkId: 280,
                  },
                },
                {
                  name: 'appnexus',
                  params: {
                    placementId: 19698628,
                  },
                },
                {
                  name: 'teads',
                  params: {
                    pageId: 149522,
                    placementId: 163688,
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_320x50-hb@320x50',
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_320x100-hb@320x100',
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_300x250-hb@300x250',
                  },
                },
                {
                  name: 'rubicon',
                  params: {
                    accountId: 23660,
                    siteId: 381482,
                    zoneId: 2114030,
                  },
                },
                {
                  name: 'openx',
                  params: {
                    delDomain: 'adevintaitaly-d.openx.net',
                    unit: 541156884,
                  },
                },
                {
                  name: 'ix',
                  params: {
                    siteId: 1131820,
                  },
                },
              ],
            },
          ],
        },
      },
      {
        //Ex: msite-bottom-addetail
        container_id: LiraContainerIds.adView.bottom,
        name: 'Adsense bottom',
        configurations: {
          adsense: [
            {
              sequence: 0,
              number_of_ads: 5,
              channel_id: MsiteVerticalAdsenseBelowTheFoldChannel[vertical],
            },
          ],
          gam: [
            {
              sequence: 1,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-msite-listing-fallbackbottom`,
              sizes: ['300x250', '320x100', '320x50'],
              bidding_systems: [
                {
                  name: 'criteo',
                  params: {
                    networkId: 280,
                  },
                },
                {
                  name: 'teads',
                  params: {
                    pageId: 149522,
                    placementId: 163688,
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_320x50-hb@320x50',
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_320x100-hb@320x100',
                  },
                },
                {
                  name: 'rubicon',
                  params: {
                    accountId: 23660,
                    siteId: 381482,
                    zoneId: 2114030,
                  },
                },
                {
                  name: 'openx',
                  params: {
                    delDomain: 'adevintaitaly-d.openx.net',
                    unit: 541156884,
                  },
                },
                {
                  name: 'ix',
                  params: {
                    siteId: 1131820,
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_300x250-hb@300x250',
                  },
                },
                {
                  name: 'appnexus',
                  params: {
                    placementId: 19698628,
                  },
                },
              ],
            },
          ],
        },
      },
      {
        //Ex: apn_box_adreply
        container_id: LiraContainerIds.adView.replyBox,
        name: 'when user sends a reply',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-msite-login-rispostaannuncio`,
              sizes: ['300x250'],
              bidding_systems: [
                {
                  name: 'ix',
                  params: {
                    siteId: 1131820,
                  },
                },
                {
                  name: 'criteo',
                  params: {
                    networkId: 280,
                  },
                },
                {
                  name: 'teads',
                  params: {
                    pageId: 149522,
                    placementId: 163688,
                  },
                },
                {
                  name: 'rubicon',
                  params: {
                    accountId: 23660,
                    siteId: 381482,
                    zoneId: 2114030,
                  },
                },
                {
                  name: 'openx',
                  params: {
                    delDomain: 'adevintaitaly-d.openx.net',
                    unit: 541156884,
                  },
                },
                {
                  name: 'appnexus',
                  params: {
                    placementId: 19698628,
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_300x250-hb@300x250',
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_320x50-hb@320x50',
                  },
                },

                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_320x100-hb@320x100',
                  },
                },
              ],
            },
          ],
        },
      },
      {
        //Ex: apn_adview_anchor
        container_id: LiraContainerIds.adView.anchorGallery,
        name: 'Msite below Gallery gallery',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-msite-adview-anchor`,
              sizes: ['320x50'],
              bidding_systems: [
                {
                  name: 'criteo',
                  params: {
                    networkId: 280,
                  },
                },
                {
                  name: 'teads',
                  params: {
                    pageId: 149522,
                    placementId: 163688,
                  },
                },
                {
                  name: 'pubmatic',
                  params: {
                    publisherId: '81997',
                    adSlot: 'Subito_320x50-hb@320x50',
                  },
                },
                {
                  name: 'appnexus',
                  params: {
                    placementId: 19698628,
                  },
                },
                {
                  name: 'rubicon',
                  params: {
                    accountId: 23660,
                    siteId: 381482,
                    zoneId: 2114030,
                  },
                },
                {
                  name: 'openx',
                  params: {
                    delDomain: 'adevintaitaly-d.openx.net',
                    unit: 541156884,
                  },
                },
                {
                  name: 'ix',
                  params: {
                    siteId: 1131820,
                  },
                },
              ],
            },
          ],
        },
      },
      {
        //Ex: apn_gallery_adview_msite
        container_id: LiraContainerIds.adView.imageIntoGalleryForProMsite,
        name: 'Adv in the gallery specific for Pro',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-msite-adview-gallery`,
              sizes: ['289x226'],
            },
          ],
        },
      },
      {
        //Ex: apn_gallery_adv_adview
        container_id: LiraContainerIds.adView.imageIntoGalleryForPrivateMsite,
        name: 'Overlay sponsor over gallery for Private',
        configurations: {
          gam: [
            {
              sequence: 0,
              unit_id: `/11825272/${vertical}/${vertical}-web/subito-${vertical}-msite-adview-galleryadv`,
              sizes: ['289x226'],
            },
          ],
        },
      },
    ],
  };
};
