import * as React from 'react';
import type { AdItem, ProfileQueryData } from '@sbt-web/networking';
import type { Shop } from '@sbt-web/network/types';
import { AdDetailCompanyPro } from '../AdDetailCompanyPro';
import { DEFAULT_ADVERTISER_NAME } from '@shared/constants';

export interface AdvertiserInfoProProps {
  item: AdItem;
  shop: Shop | null;
  advertiserProfile: ProfileQueryData | null;
}

export const AdvertiserInfoPro = ({
  shop,
  item,
  advertiserProfile,
}: AdvertiserInfoProProps) => {
  if (shop) {
    const {
      name,
      logo,
      adsCount,
      address,
      urls,
      website,
      shopId,
      openingTime,
    } = shop;

    return (
      <AdDetailCompanyPro
        name={name}
        adsCount={adsCount}
        address={address}
        openingTime={openingTime}
        shopId={shopId}
        website={website}
        url={urls?.default}
        logo={logo?.medium?.secureuri}
        item={item}
      />
    );
  }

  return (
    <AdDetailCompanyPro
      name={advertiserProfile?.username ?? DEFAULT_ADVERTISER_NAME}
      item={item}
    />
  );
};
