import { useImageNotLoaded } from '@sbt-web/hooks';
import { ButtonText } from '@sbt-web/ui';
import classNames from 'classnames';
import React from 'react';
import type { PresenceStatus } from '@sbt-web/network/types';

import styles from './index.module.scss';

export enum PrivateUserBadgeSize {
  Normal = 'normal',
  Small = 'small',
}

interface Props {
  name: string;
  url: string;
  handleClick: () => void;
  size?: PrivateUserBadgeSize;
  picture?: string;
  presenceStatus?: PresenceStatus;
}

interface LetterBadgeProps {
  name: string;
}

const LetterBadge = ({ name }: LetterBadgeProps) => {
  return <ButtonText size="small">{name[0]?.toUpperCase() || ''}</ButtonText>;
};

export const UserBadge = ({
  name,
  url,
  handleClick,
  size = PrivateUserBadgeSize.Normal,
  picture,
  presenceStatus,
}: Props) => {
  const imageNotLoaded = useImageNotLoaded(picture ?? '');

  return (
    <a
      href={url}
      title={name}
      onClick={handleClick}
      className={classNames(styles.rounded_user_badge, {
        [styles.small]: size === PrivateUserBadgeSize.Small,
      })}
    >
      {imageNotLoaded || !picture ? (
        <LetterBadge name={name} />
      ) : (
        <img alt={name} className={styles.picture} src={picture} />
      )}
      {presenceStatus === 'ONLINE' && (
        <span
          className={styles.presence_online_status}
          aria-label="Online ora"
        ></span>
      )}
    </a>
  );
};
