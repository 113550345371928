import type {
  CategoryId,
  ItemAdvertiser,
  ProfileQueryData,
} from '@sbt-web/networking';
import { Badge, Divider, Subhead } from '@sbt-web/ui';
import { DEFAULT_ADVERTISER_NAME } from '@shared/constants';
import createShopUrl from '@shared/helpers/ShopUrl';
import { getAdvertiserName, isPro } from '@shared/models/Advertiser';
import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { ShopLogo } from './ShopLogo';
import { ContactButton } from '../ContactUser';
import { getShopLabelByCategoryId } from '@sbt-web/utils';
import {
  PrivateUserBadgeSize,
  PrivateUserProfileBadge,
} from '@client/components/Detail/PrivateUserProfileBadge';
import type { TrustProfileInfo, Shop } from '@sbt-web/network/types';

import '@sbt-web/user-rating/dist/index.css';
import ownClasses from './index.module.scss';

interface Props {
  advertiser: ItemAdvertiser;
  categoryId: CategoryId;
  shopId?: string;
  shop: Shop | null;
  advertiserProfile: ProfileQueryData | null;
  trustInfo: TrustProfileInfo | null;
  showContactButton?: boolean;
}

export const CompanyBadge = ({ categoryId }: { categoryId: CategoryId }) => {
  const label = getShopLabelByCategoryId(categoryId);

  return label ? (
    <Badge classes={[ownClasses['company-badge']]}>{label}</Badge>
  ) : null;
};

type ShopWrapperProps = PropsWithChildren<{
  advertiser: ItemAdvertiser;
  showContactButton: boolean;
}>;
const ShopWrapper = ({
  children,
  advertiser,
  showContactButton,
}: ShopWrapperProps) => {
  const { shopName, shopId } = advertiser;

  const shopUrl =
    shopId && shopName
      ? createShopUrl(
          process.env.NEXT_PUBLIC_IMPRESAPIU_BASE_URL,
          shopId,
          shopName
        )
      : undefined;

  return shopUrl ? (
    <a
      href={shopUrl}
      className={classnames(
        ownClasses['shop-details-wrapper'],
        ownClasses['shop-link']
      )}
    >
      {children}
    </a>
  ) : (
    <div className={ownClasses['shop-with-contact']}>
      <div className={ownClasses['shop-details-wrapper']}>{children}</div>
      {showContactButton && <ContactButton />}
    </div>
  );
};

export const UserDetails = ({
  advertiser,
  categoryId,
  advertiserProfile,
  shop,
  trustInfo,
  showContactButton,
}: Props) => (
  <>
    <Divider spacing="md" />

    {isPro(advertiser.type) ? (
      <ShopWrapper
        advertiser={advertiser}
        showContactButton={showContactButton || false}
      >
        <ShopLogo logoURL={shop?.logo?.medium?.secureuri} />
        <div className={ownClasses['user-details-name']}>
          <CompanyBadge categoryId={categoryId} />
          <Subhead element="p" classes={[ownClasses['user-name']]}>
            {getAdvertiserName(advertiser.type, shop, advertiserProfile)}
          </Subhead>
        </div>
      </ShopWrapper>
    ) : (
      <PrivateUserProfileBadge
        name={advertiserProfile?.username ?? DEFAULT_ADVERTISER_NAME}
        userId={advertiser.userId}
        size={PrivateUserBadgeSize.Small}
        pulseEventName="click-on-name-AD-Detail"
        picture={advertiserProfile?.picture}
        score={trustInfo?.reputation.feedback?.sourceScores.MEMBER}
        presenceStatus={
          trustInfo?.presence.enabled
            ? (trustInfo?.presence.status ?? undefined)
            : undefined
        }
        reviewCount={trustInfo?.reputation.feedback?.sourceCounts.MEMBER}
        showContactButton={showContactButton}
      />
    )}
    <Divider topSpacing="md" bottomSpacing="null" />
  </>
);

export default UserDetails;
