import { BodyText } from '@sbt-web/ui';
import cn from 'classnames';
import React from 'react';
import type { PresenceStatus } from '@sbt-web/network/types';

import styles from './index.module.scss';

interface Props {
  presenceText: string;
  presenceStatus: PresenceStatus;
}

export const Presence = ({ presenceText, presenceStatus }: Props) => {
  return (
    <BodyText
      size="small"
      classes={[
        cn(styles.presence_status, {
          [styles.online]: presenceStatus === 'ONLINE',
        }),
      ]}
    >
      {presenceText}
    </BodyText>
  );
};
