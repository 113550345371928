import React, { useState, useEffect } from 'react';
import { getPublicUser } from '@sbt-web/auth';
import { ContactButton } from './ContactButton';
import { PhoneButton } from './PhoneButton';
import { getSearchValue } from '../../utils';
import type { AdReplyButtonsProps } from './types';
import classes from './index.module.scss';

export const AdReplyButtons = ({
  isMobile,
  onContactButtonClick,
  contactAsSecondaryButton,
  adUrn,
  showPhone,
  item,
}: AdReplyButtonsProps) => {
  const [hasMounted, setHasMounted] = useState(false);
  const searchValue = getSearchValue();

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return (
      <>
        <div aria-hidden="true" className={classes.skeleton} />
        <span
          role="status"
          aria-live="polite"
          className={classes.visuallyHidden}
        >
          Caricamento pulsanti di contatto in corso
        </span>
      </>
    );
  }

  const isUserLogged = getPublicUser()?.id;
  const isNonLoggedUserWithShowPhone = !isUserLogged && showPhone;

  const phoneButton = () => {
    const buttonLabel = isNonLoggedUserWithShowPhone
      ? 'Mostra numero'
      : undefined;

    const buttonDesign = isNonLoggedUserWithShowPhone ? 'solid' : 'outline';

    return (
      <PhoneButton
        adUrn={adUrn}
        isMobile={isMobile}
        search={searchValue}
        item={item}
        buttonLabelDesktop={buttonLabel}
        buttonLabelMobile={buttonLabel}
        buttonDesign={buttonDesign}
        buttonSize="large"
        pulseEvent={{
          name: 'Mostra Numero',
          pageType: 'ad_detail',
        }}
      />
    );
  };

  /*
   * Return swapped CTA positions for non-logged users and ads with the phone number
   */
  if (isNonLoggedUserWithShowPhone) {
    return (
      <div className={classes.switchedTouchPoints}>
        <ContactButton
          isSecondary={true}
          onClick={onContactButtonClick}
          showLabel={false}
          showIcon={true}
        />

        {phoneButton()}
      </div>
    );
  }

  return (
    <>
      {showPhone && phoneButton()}

      <ContactButton
        isSecondary={contactAsSecondaryButton}
        onClick={onContactButtonClick}
      />
    </>
  );
};
