import React, { type PropsWithChildren } from 'react';
import { SkeletonWithAdv } from '@client/components/Adv/SkeletonWithAdv';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';

const SkeletonWithAdvForAdView = ({ children }: PropsWithChildren) => {
  return (
    <SkeletonWithAdv
      skyscraperLeft={{
        id: LiraContainerIds.adView.skyscraperLeft,
        enabled: true,
      }}
      skyscraperRight={{
        id: LiraContainerIds.adView.skyscraperRight,
        enabled: true,
      }}
      billboard={{ id: LiraContainerIds.adView.top, enabled: true }}
    >
      {children}
    </SkeletonWithAdv>
  );
};

export { SkeletonWithAdvForAdView };
