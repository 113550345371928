import { LazyVideoPreroll } from '@client/components/Adv/GPT/sdkIntegration';
import {
  useAdvGalleryPlacement,
  useAdvSponsorshipThumbnail,
} from '@sbt-web/adv';
import { useClientHints, useViewport } from '@sbt-web/hooks';
import {
  CategoryStore,
  type AdItem,
  type ItemCategory,
} from '@sbt-web/networking';
import { Button, Close, Divider } from '@sbt-web/ui';
import { shouldReduceDataUse } from '@sbt-web/utils';
import { getOrCreatePulse } from '@tools/tracking/utils';
import dynamic from 'next/dynamic';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import Carousel from './Carousel';
import cn from 'classnames';

import ownClasses from './styles.module.scss';

const Thumbnails = dynamic(() => import('./Thumbnails'), {
  ssr: false,
});

export const buildImageViewTrackingData = (
  category: ItemCategory,
  adUrn: string,
  current: number,
  total: number
) => {
  const macroCat = CategoryStore.getCategoryById(category.parentId);

  const baseData: {
    type: 'Engagement';
    name: string;
    object: Record<string, string | Record<string, unknown>>;
  } = {
    type: 'Engagement',
    name: 'View Image in Addetail',
    object: {
      '@id': `sdrn:subito:classified:gallery:element:gallery-image-${
        current + 1
      }/${Math.max(total, 1)}`,
      '@type': 'UIElement',
      elementType: 'Img',
      relatedTo: {
        '@type': 'ClassifiedAd',
        '@id': `sdrn:subito:classified:${adUrn}`,
        category: `${macroCat.friendly}>${category.friendlyName}`,
      },
    },
  };

  const imageElement = document
    .getElementById(`gallery-cell-${current}`)
    ?.querySelector('img');

  if (imageElement != null) {
    baseData.object.size = `${imageElement.width}x${imageElement.height}`;
  }

  return baseData;
};

interface Props {
  ad: AdItem;
}

const GalleryComponent: React.FunctionComponent<Props> = ({ ad }) => {
  const { images, category, subject: alt, urn: adUrn } = ad;

  const containerRef = createRef<HTMLDivElement>();

  const [currentIndex, setCurrentIndex] = useState(0);
  const { isMobile, isDesktop } = useViewport();

  const { effectiveConnectionType, saveData, deviceMemory } = useClientHints();

  const reduceDataUse = shouldReduceDataUse({
    effectiveConnectionType,
    saveData,
    deviceMemory,
  });

  useEffect(() => {
    getOrCreatePulse()?.queueEvent(
      buildImageViewTrackingData(category, adUrn, currentIndex, images.length)
    );
  }, [adUrn, category, currentIndex, images.length]);

  const { advLoaded } = useAdvGalleryPlacement();

  const { advSponsorshipThumbnail, goToAdv, setGoToAdv } =
    useAdvSponsorshipThumbnail(
      images.length,
      process.env.NEXT_PUBLIC_ASSETS_BASE_URL,
      ownClasses
    );

  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    if (fullScreen) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setFullScreen(false);
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [fullScreen]);

  const handleSwitchFullscreen = useCallback(() => {
    setFullScreen(true);
  }, []);

  return (
    <div
      ref={containerRef}
      className={cn({ [ownClasses['full-size']]: fullScreen })}
    >
      {isDesktop ? <LazyVideoPreroll parentRef={containerRef} /> : null}

      <Carousel
        images={images}
        alt={alt}
        category={category}
        onChange={setCurrentIndex}
        currentIndex={currentIndex}
        reduceDataUse={reduceDataUse}
        goToAdv={goToAdv}
        setGoToAdv={setGoToAdv}
        ad={ad}
        switchFullscreen={handleSwitchFullscreen}
        fullScreen={fullScreen}
      />
      {(!isMobile && images.length >= 2) ||
      (!isMobile && advLoaded && images.length) ? (
        <>
          <Divider spacing="null" classes={[ownClasses['gallery-divider']]} />

          <Thumbnails
            images={images}
            onClick={(index: number) => {
              setCurrentIndex(index);
              setGoToAdv(false);
            }}
            reduceDataUse={reduceDataUse}
            currentIndex={currentIndex}
            advThumbnail={advSponsorshipThumbnail}
            advLoaded={advLoaded}
            goToAdv={goToAdv}
          />
        </>
      ) : null}
      {fullScreen && (
        <Button
          design="outline-white"
          size="large"
          aria-label="Chiudi Gallery Fullscreen"
          onClick={() => setFullScreen(false)}
          icon={<Close width={24} height={24} />}
          classes={[ownClasses['close-dialog']]}
          tabIndex={0}
        />
      )}
    </div>
  );
};

export default GalleryComponent;
export type { Props };
