import { useContext, useEffect, useState } from 'react';
import { AdItem, FeatureURI } from '@sbt-web/networking';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';

const GALLERY_PLACEMENT_TOGGLE = 'subito.web.adv.detail-gallery';

export const useLiraLazyOverlayGalleryForPrivate = ({
  ad,
  isDesktop,
}: {
  ad: AdItem;
  isDesktop: boolean;
}): void => {
  const [advDetailGalleryToggle, setAdvDetailGalleryToggle] = useState(false);
  const { optimizely } = useContext(OptimizelySubitoContext);
  //TODO: please not under houston toggle, handle in Lira UI directly
  useEffect(() => {
    optimizely?.onReady().then(() => {
      setAdvDetailGalleryToggle(
        optimizely.isFeatureEnabled(GALLERY_PLACEMENT_TOGGLE)
      );
    });
  }, [optimizely, isDesktop]);

  useEffect(() => {
    let slots: Array<string> = [];

    if (
      !ad?.features?.[FeatureURI.ItemShippingCostTuttoSubito] &&
      ad?.advertiser?.type === 0 &&
      ad?.images?.length > 0 &&
      advDetailGalleryToggle
    ) {
      if (isDesktop) {
        slots.push(LiraContainerIds.adView.imageIntoGalleryForPrivate);
      } else {
        slots = [LiraContainerIds.adView.imageIntoGalleryForPrivateMsite];
      }
    }
    if (slots.length > 0) {
      window.liraSDKQueue?.push(() => {
        window.liraSdk?.fetchLazyLoadAd(slots);
      });
    }
  }, [ad?.advertiser?.type, ad?.features, advDetailGalleryToggle, isDesktop]);
};
